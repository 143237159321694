<template>
    <div class="c-accordion" :class="{ 'c-accordion--open': isOpen }">
        <div
            v-tab-focus.onspace="() => toggleAccordion()"
            class="c-accordion__header"
            :class="{
                [headerClass]: !!headerClass,
            }"
        >
            <slot name="header"></slot>
            <div class="c-accordion__header-icon">
                <BaseIcon
                    :icon="isOpen ? 'global--minus' : 'global--plus'"
                    size="16px"
                    color="primary"
                />
            </div>
        </div>
        <div v-show="isOpen" class="c-accordion__body">
            <slot name="body"></slot>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { ACCORDION_OPENED } from '~coreModules/core/js/global-event-constants';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Accordion',
    props: {
        headerClass: {
            type: String,
            default: '',
        },
        isInitiallyOpen: {
            type: Boolean,
            default: false,
        },
        isTopBorderless: {
            type: Boolean,
            default: false,
        },
        moduleId: {
            type: String,
            default: '',
        },
        analyticsTitle: {
            type: String,
            default: '',
        },
    },
    emits: ['toggle'],
    data() {
        return {
            isOpen: this.isInitiallyOpen,
        };
    },
    methods: {
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        toggleAccordion() {
            this.isOpen = !this.isOpen;

            if (this.isOpen) {
                this.trackGlobalEvent({
                    type: ACCORDION_OPENED,
                    data: {
                        accordionTitle: this.analyticsTitle,
                    },
                });
            }

            this.$emit('toggle', this.isOpen);
        },
    },
};
</script>

<style lang="scss">
    .c-accordion {
        border-bottom: 1px solid $nu-white;

        &__header {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: $nu-spacer-1pt5 0;
        }

        &__header-icon {
            margin: 0 $nu-spacer-2 0 $nu-spacer-3;
            height: 100%;
        }

        &__body {
            padding-bottom: $nu-spacer-1pt5;
        }
    }
</style>
